import React from 'react';
import styles from './Model.module.css';
import { ModelBox } from './ModelViewer';

export function ModelList(props) {
    const { data } = props;

    return (
        <div className={styles.container}>
            <div className={styles.list_wrapper}>
                {data.map((item, index) => {
                    return <ModelBox scale="0.8" data={item} key={index} />;
                })}
            </div>
        </div>
    );
}
