import React from 'react';
import styles from './Breadcrumb.module.css';
import { Link, useLocation } from 'react-router-dom';

export function Breadcrumb() {
    const location = useLocation();
    const { pathname } = location;
    const pathnames = pathname.split('/');

    return (
        <div className={styles.breadcrumb_wrapper}>
            {pathnames.length > 0 ? (
                <Link to="/" className={styles.breadcrumb_item}>
                    Home
                </Link>
            ) : (
                <div className={styles.breadcrumb_item}>Home</div>
            )}
            {pathnames.map((name, index) => {
                const routeTo = `${pathnames.slice(0, index + 1).join('/')}`;
                const isLast = index === pathnames.length - 1;
                if (index !== 0) {
                    return isLast ? (
                        <div className={styles.item_box} key={index}>
                            <span> / </span>
                            <div className={styles.breadcrumb_item}>{name}</div>
                        </div>
                    ) : (
                        <div className={styles.item_box} key={index}>
                            <span> / </span>
                            <Link to={`${routeTo}`} className={styles.breadcrumb_item}>
                                {name}
                            </Link>
                        </div>
                    );
                } else {
                    return null;
                }
            })}
        </div>
    );
}
