import { DesktopMac, PhoneIphone, Architecture, Movie, ViewInAr, Category } from '@styled-icons/material-rounded';

export const categories = [
    {
        name: 'Web',
        category: 'web',
        icon: <DesktopMac style={{ width: '100%' }} />,
    },
    {
        name: 'App',
        category: 'app',
        icon: <PhoneIphone style={{ width: '100%' }} />,
    },
    {
        name: 'Design',
        category: 'design',
        icon: <Architecture style={{ width: '100%' }} />,
    },
    {
        name: 'Media',
        category: 'media',
        icon: <Movie style={{ width: '100%' }} />,
    },
    {
        name: '3D Model',
        category: '3d',
        icon: <ViewInAr style={{ width: '100%' }} />,
    },
    {
        name: 'other',
        category: 'other',
        icon: <Category style={{ width: '100%' }} />,
    },
];
