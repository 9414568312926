import React, { useEffect, useState } from 'react';
import styles from './PortfolioPage.module.css';
import { Link } from 'react-router-dom';
import { Loading, PortfolioFeatured } from '../../components';
import { categories } from '../../data/portfolioCategory';
import portfolios from '../../json/portfolios.json';

export function PortfolioPage() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchPortfoliosFeatured();
    }, []);

    const fetchPortfoliosFeatured = async (category) => {
        const res = portfolios.filter((el) => el.isFeatured === true);
        setData(res);
        setLoading(false);
    };

    return (
        <div className="container">
            <div className={styles.page_title}>Portfolio</div>
            <div className={styles.featured_title}>
                <div className={styles.divider} />
                Featured
                <div className={styles.divider} />
            </div>
            <div className={styles.featured_section}>
                <div className={styles.featured_wrapper}>
                    {data.length !== 0 ? (
                        data.map((item, index) => <PortfolioFeatured data={item} key={index} />)
                    ) : (
                        <div className={styles.null_text}>Loading...</div>
                    )}
                </div>
            </div>
            <div className={styles.section_divider} />
            <div className={styles.category_wrapper}>
                {categories.map((item, index) => {
                    return (
                        <Link to={`/portfolio/${item.category}`} className={styles.category} key={index}>
                            <div className={styles.category_btn}>{item.icon}</div>
                            <div className={styles.category_title}>{item.name}</div>
                        </Link>
                    );
                })}
            </div>
            {loading && <Loading />}
        </div>
    );
}
