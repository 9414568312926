import React, { useEffect, useRef } from 'react';
import styles from './Loading.module.css';
import lottie from 'lottie-web';
import loadingAnimation from '../../animation/loading.json';

export function Loading() {
    const anime = useRef(null);
    useEffect(() => {
        lottie.loadAnimation({
            container: anime.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: loadingAnimation,
        });
        return () => lottie.stop();
    }, []);
    return (
        <div className={styles.container}>
            <div className={styles.animation} ref={anime}></div>
            <div className={styles.text}>Loading...</div>
        </div>
    );
}
