import React from 'react';
import styles from './PageNotFound.module.css';
import { Link } from 'react-router-dom';

export function PageNotFound() {
    return (
        <div className="container">
            <div className={styles.page_title}>404</div>
            <div className={styles.page_subtitle}>Oops! There's nothing here...</div>
            <div className={styles.note}>Please check if the url is correct :(</div>
            <Link to={`/`} className={styles.btn}>
                Go Home
            </Link>
        </div>
    );
}
