import React from 'react';
import styles from './Portfolio.module.css';
import { Portfolio } from './Portfolio';

export function PortfolioList(props) {
    const { data } = props;

    return (
        <div className={styles.container}>
            <div className={styles.list_wrapper}>
                {data.map((item, index) => {
                    return <Portfolio data={item} key={index} />;
                })}
            </div>
        </div>
    );
}
