import React from 'react';
import styles from './Button.module.css';
import { Link } from 'react-router-dom';
import { ReactComponent as ArrowChunky } from '../../svg/arrow_chunky.svg';

export function HandPointButton({ linkTo, target, style, text = 'View' }) {
    return (
        <Link to={linkTo} className={style} target={target}>
            <div className={styles.btn}>
                <ArrowChunky className={styles.btn_icon} />
                <div className={styles.btn_text}>{text}</div>
            </div>
        </Link>
    );
}

export function HandPointButtonExternal({ linkTo, target, style, text = 'View' }) {
    return (
        <a href={linkTo} className={style} target={target}>
            <div className={styles.btn}>
                <ArrowChunky className={styles.btn_icon} />
                <div className={styles.btn_text}>{text}</div>
            </div>
        </a>
    );
}
