import React, { useEffect, useState } from 'react';
import styles from './HomePage.module.css';
import { ReactComponent as Sparkle } from '../../svg/sparkle.svg';
import { ReactComponent as Arrow01 } from '../../svg/arrow_01.svg';
import { ReactComponent as Underline } from '../../svg/under_line.svg';
import { ReactComponent as DesignBox } from '../../svg/design_box.svg';
import { ReactComponent as DesignSelect } from '../../svg/design_select.svg';
import { ReactComponent as DesignPen } from '../../svg/design_pen.svg';
import { ReactComponent as DesignPenPath } from '../../svg/design_pen_path.svg';
import { ReactComponent as IphoneMockup } from '../../svg/iphone_mockup.svg';
import { ReactComponent as ArrowDouble } from '../../svg/arrow_double.svg';
import { sections, webSkills, appSkills, designSkills, designUnits } from '../../data/homeData';
import { HandPointButton, HandPointButtonExternal } from '../../components';
import { categories } from '../../data/portfolioCategory';

export function HomePage() {
    const [dot, setDot] = useState('section1');

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll = () => {
        const windowHeight = window.innerHeight;
        if (window.scrollY <= windowHeight) {
            setDot(sections[0].id);
        } else if (window.scrollY > windowHeight && window.scrollY <= windowHeight * 2) {
            setDot(sections[1].id);
        } else if (window.scrollY > windowHeight * 2 && window.scrollY <= windowHeight * 3) {
            setDot(sections[2].id);
        } else if (window.scrollY > windowHeight * 3 && window.scrollY <= windowHeight * 4) {
            setDot(sections[3].id);
        } else {
          setDot(sections[4].id);
        }
    };

    const scrollToAnchor = (anchorName) => {
        if (anchorName) {
            const anchorElement = document.getElementById(anchorName);
            if (anchorElement) {
                anchorElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    };

    return (
        <div className="container">
            <div className={styles.block_wrapper_logo} id="intro">
                <div className={styles.logo_name}>
                    Hi, I'm Sofia!
                    <Sparkle className={styles.logo_sparkle} />
                </div>
                <Arrow01 className={styles.arrow01} />
            </div>
            <div className={styles.block_wrapper} id="web">
                <div className={styles.page_title}>A Web Developer</div>
                <div className={styles.page_subtitle}>
                    Fronted & Backend
                    <Underline className={styles.underline} />
                </div>
                <div className={styles.web_content_wrapper}>
                    <div className={styles.web_content}>
                        <div className={styles.web_description}>
                            <span className={styles.web_description_title}>- Frontend</span> <br />
                            Basic HTML, CSS, and JavaScript knowledge, familiar with frontend framework like React{' '}
                            <br />
                            <span className={styles.web_description_title}>- Backend</span> <br />
                            Node.js, Express.js & MongoDB
                        </div>
                        <HandPointButton
                            linkTo={`/portfolio/web/`}
                            text={'View Web Portfolio'}
                            style={styles.web_btn}
                        />
                    </div>
                    <div className={styles.web_content}>
                        <div className={styles.icon_group}>
                            {webSkills.map((item, index) => {
                                return (
                                    <img
                                        alt={item}
                                        src={`https://cdn.simpleicons.org/${item.replaceAll(' ', '')}/484848`}
                                        className={styles.icon}
                                        key={index + item}
                                    />
                                );
                            })}
                        </div>
                        <div className={styles.web_box_wrapper}>
                            <div className={styles.web_box_back}>
                                <div className={styles.web_img_box}>
                                    <img className={styles.web_img} src="/img/the_hunger_games_01.jpg" alt="web back" />
                                </div>
                            </div>
                            <div className={styles.web_box_front}>
                                <div className={styles.web_img_box}>
                                    <img
                                        className={styles.web_img}
                                        src="/img/beverage_comparator_01.jpg"
                                        alt="web front"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.block_wrapper} id="app">
                <div className={styles.page_title}>App</div>
                <div className={styles.app_content_wrapper}>
                    <div className={styles.app_content}>
                        <div className={styles.icon_group_app}>
                            {appSkills.map((item, index) => {
                                return (
                                    <img
                                        alt={item}
                                        src={`https://cdn.simpleicons.org/${item.replaceAll(' ', '')}/484848`}
                                        className={styles.icon}
                                        key={index + item}
                                    />
                                );
                            })}
                        </div>
                    </div>
                    <div className={styles.app_content}>
                        <div className={styles.app_box}>
                            <div className={styles.app_img_box}>
                                <img
                                    className={styles.app_img_back}
                                    src="/img/trip_can_planner.png"
                                    alt="app portfolio"
                                />
                                <img
                                    className={styles.app_img_front}
                                    src="/img/trip_can_splash.png"
                                    alt="app portfolio"
                                />
                            </div>
                            <IphoneMockup className={styles.app_mask} />
                        </div>
                    </div>
                    <div className={styles.app_content}>
                        <div className={styles.app_description}>
                            Using React Native with EXPO to build my first app. <br />
                            Also try to build server API by Node.js, Express.js & MongoDB.
                        </div>
                        <HandPointButton
                            linkTo={`/portfolio/app/`}
                            text={'View App Portfolio'}
                            style={styles.app_btn}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.block_wrapper} id="design">
                <div className={styles.page_title}>Also interested in Design</div>
                <div className={styles.design_content_wrapper}>
                    <div className={styles.design_content}>
                        <div className={styles.design_box_wrapper}>
                            <div className={styles.design_img_box}>
                                <img
                                    className={styles.design_img}
                                    src="/img/design_system.png"
                                    alt="design portfolio"
                                />
                            </div>
                            <DesignBox className={styles.design_box} />
                            <div className={styles.design_box_tag}>360 X 360</div>
                            <DesignSelect className={styles.design_select} />
                        </div>
                    </div>
                    <div className={styles.design_content}>
                        <div className={styles.icon_group_design}>
                            {designSkills.map((item, index) => {
                                return (
                                    <img
                                        alt={item}
                                        src={`https://cdn.simpleicons.org/${item.replaceAll(' ', '')}/484848`}
                                        className={styles.icon}
                                        key={index + item}
                                    />
                                );
                            })}
                        </div>
                        <div className={styles.design_pen_wrapper}>
                            <DesignPenPath className={styles.design_pen_path} />
                            <DesignPen className={styles.design_pen} />
                        </div>
                        <HandPointButton
                            linkTo={`/portfolio/design/`}
                            text={'View Design Portfolio'}
                            style={styles.design_btn}
                        />
                    </div>
                    <div className={styles.design_content}>
                        <div className={styles.design_unit_wrapper}>
                            {designUnits.map((item, index) => (
                                <div className={styles.design_unit} key={index + item}>
                                    {item}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className={styles.block_wrapper} id="others">
                <div className={styles.page_title}>Open to other skills</div>
                <div className={styles.others_content_wrapper}>
                    <div className={styles.others_content}>
                        <div className={styles.icon_group_others}>
                            {otherSkills.map((item, index) => {
                                return (
                                    <img
                                        alt={item}
                                        src={`https://cdn.simpleicons.org/${item.replaceAll(' ', '')}/484848`}
                                        className={styles.icon}
                                        key={index + item}
                                    />
                                );
                            })}
                        </div>
                    </div>
                    <div className={styles.others_content}>
                        <div className={styles.model_wrapper}>
                            <ModelViewer data={modelsData[6]} />
                        </div>
                        <HandPointButton
                            linkTo={`/portfolio/3d/`}
                            text={'View 3D Model Portfolio'}
                            style={styles.others_btn}
                        />
                    </div>
                    <div className={styles.others_content}>
                        <Axis className={styles.axis} />
                    </div>
                </div>
            </div> */}
            <div className={styles.block_wrapper} id="conclusion">
                <div className={styles.page_title}>Last but not least...</div>
                <div className={styles.conclusion_content_wrapper}>
                    <div className={styles.conclusion_content}>
                        <div className={styles.category_wrapper}>
                            {categories.map((item, index) => {
                                return (
                                    <div className={styles.category} key={index}>
                                        <div className={styles.category_icon}>{item.icon}</div>
                                    </div>
                                );
                            })}
                        </div>
                        <HandPointButton
                            linkTo={`/portfolio/`}
                            text={'View My Portfolio'}
                            style={styles.conclusion_btn}
                        />
                    </div>
                    <ArrowDouble className={styles.arrow_double} />
                    <div className={styles.conclusion_content}>
                        <div className={styles.conclusion_icon_box}>
                            <img
                                alt="GitHub"
                                src="https://cdn.simpleicons.org/GitHub/484848"
                                className={styles.conclusion_icon}
                            />
                        </div>
                        <HandPointButtonExternal
                            linkTo={'https://github.com/BeanBeam11/'}
                            target={'_blank'}
                            text={'Visit My Github'}
                            style={styles.conclusion_btn}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.dot_wrapper}>
                <div className={styles.dot_line}></div>
                {sections.map((item, index) => {
                    const dotStyle = {
                        backgroundColor: dot === item.id ? '#F4F4F2' : '#484848',
                    };
                    return (
                        <div className={styles.dot_box} key={index + item.name} onClick={() => scrollToAnchor(item.id)}>
                            <div className={styles.dot} style={dotStyle}></div>
                            <div className={styles.dot_name}>{item.name}</div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
