import React, { useEffect, useState } from 'react';
import styles from './PortfolioListPage.module.css';
import { useLocation } from 'react-router-dom';
import { Loading, Breadcrumb, PortfolioList } from '../../components';
import { categories } from '../../data/portfolioCategory';
import { PageNotFound } from '../index';
import portfolios from '../../json/portfolios.json';

const ListItem = ({ category, data }) => {
    switch (category) {
        // case '3d':
        //     return <ModelList data={data} />;
        default:
            return <PortfolioList data={data} />;
    }
};

export function PortfolioListPage() {
    const location = useLocation();
    const category = location.pathname.split('/')[2];
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const name = categories.find((el) => el.category === category)?.name;

    useEffect(() => {
        if (name) fetchPortfoliosByCategory();
    }, []);

    if (!name) {
        return <PageNotFound />;
    }

    const fetchPortfoliosByCategory = async () => {
      if(category === '3d') {
        setData([]);
        setLoading(false);
        return
      }
      const res = portfolios.filter((el) => el.category === category);
      setData(res);
      setLoading(false);
    };

    return (
        <div className="container">
            <div className={styles.page_title}>{name}</div>
            <Breadcrumb />
            <div className={styles.content_wrapper}>
                {data.length !== 0 ? (
                    <ListItem category={category} data={data} />
                ) : (
                    <div className={styles.null_text}>Coming Soon...</div>
                )}
            </div>
            {loading && <Loading />}
        </div>
    );
}
