import React from 'react';
import styles from './AboutPage.module.css';

export function AboutPage() {
    return (
        <div className="container">
            <div className={styles.page_title}>About</div>
            <div className={styles.page_subtitle}>⚠️ This page is still under development ⚠️</div>
        </div>
    );
}
