import React, { useEffect, useState } from 'react';
import styles from './PortfolioDetailPage.module.css';
import { useLocation } from 'react-router-dom';
import { Loading, Breadcrumb } from '../../components';
import { PageNotFound } from '../index';
import portfolios from '../../json/portfolios.json';
import { Schedule } from '@styled-icons/material-outlined';

export function PortfolioDetailPage() {
    const location = useLocation();
    const slug = location.pathname.split('/')[3];
    const [portfolio, setPortfolio] = useState('');
    const [loading, setLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        fetchPortfolio(slug);
    }, []);

    if (hasError) {
        return <PageNotFound />;
    }

    const fetchPortfolio = async (slug) => {
        const res = portfolios.filter((el) => el.slug === slug);
        if (res[0]) {
            setPortfolio(res[0]);
        } else {
            setHasError(true);
        }
        setLoading(false);
    };

    return (
        <div className="container">
            <div className={styles.page_title}>作品集</div>
            <Breadcrumb />
            {loading ? (
                <Loading />
            ) : (
                <div className={styles.content_wrapper}>
                    <div className={styles.img_wrapper}>
                        <img
                            className={`${styles.img} ${styles.img_cover}`}
                            src={portfolio.images[0]}
                            alt="portfolio"
                        />
                        {portfolio.images.map((item, index) => {
                            if (index !== 0) {
                                return <img className={styles.img} src={item} alt="portfolio" key={index} />;
                            } else {
                                return null;
                            }
                        })}
                    </div>
                    <div className={styles.info_wrapper}>
                        <div className={styles.title}>{portfolio.title}</div>
                        <div className={styles.subtitle}>{portfolio.subtitle}</div>
                        <div className={styles.description}>{portfolio.description}</div>
                        <div className={styles.tools_wrapper}>
                            {portfolio.tools.map((item, index) => {
                                return (
                                    <div className={styles.tools_box} key={index}>
                                        {item}
                                    </div>
                                );
                            })}
                        </div>
                        <div className={styles.links_wrapper}>
                            {portfolio.links.map((item, index) => {
                                return (
                                    <a href={item.url} target="_blank" rel="noopener noreferrer" key={index}>
                                        <div className={styles.view_btn}>{item.title}</div>
                                    </a>
                                );
                            })}
                        </div>
                        <div className={styles.date_wrapper}>
                            <Schedule className={styles.date_icon} />
                            <div>{`${portfolio.start_date} - ${portfolio.end_date}`}</div>
                        </div>
                        {portfolio.note.length === 0 ? null : (
                            <div className={styles.note_wrapper}>
                                <div className={styles.note_title}>備註：</div>
                                <div className={styles.note_text}>{portfolio.note}</div>
                            </div>
                        )}
                    </div>
                    <div className={`${styles.img_wrapper} ${styles.img_cover_mobile}`}>
                        <img className={styles.img} src={portfolio.images[0]} alt="portfolio" />
                    </div>
                </div>
            )}
        </div>
    );
}
