import React from 'react';
import { useRoutes } from 'react-router-dom';
import {
    HomePage,
    PortfolioPage,
    PortfolioListPage,
    PortfolioDetailPage,
    // ModelDetailPage,
    AboutPage,
    PageNotFound,
} from '../../pages';

export const AllRoutes = () => {
    const routes = useRoutes([
        {
            path: '/',
            exact: true,
            element: <HomePage />,
        },
        {
            path: '/portfolio',
            exact: true,
            element: <PortfolioPage />,
        },
        {
            path: '/portfolio/:category',
            exact: true,
            element: <PortfolioListPage />,
        },
        // {
        //     path: '/portfolio/3d/:slug',
        //     exact: true,
        //     element: <ModelDetailPage />,
        // },
        {
            path: '/portfolio/:category/:slug',
            exact: true,
            element: <PortfolioDetailPage />,
        },
        {
            path: '/about',
            exact: true,
            element: <AboutPage />,
        },
        {
            path: '*',
            element: <PageNotFound />,
        },
    ]);

    return routes;
};
