import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Header, Footer, BackToTop, ScrollToTop, AllRoutes } from './components';

function App() {
  return (
    <div className="App">
      <ScrollToTop />
      <Header />
      <AllRoutes />
      <Footer />
      <BackToTop />
    </div>
  );
}

export default App;
