import React from 'react';
import styles from './Portfolio.module.css';
import { Link } from 'react-router-dom';
import { subString } from '../../utils/formatter';

export function Portfolio(props) {
    const { data } = props;

    return (
        <Link to={`/portfolio/${data.category}/${data.slug}`} className={styles.item_box} state={{ id: data._id }}>
            <div className={styles.item_img_box}>
                <img className={styles.item_img} src={data.images[0]} alt="portfolio" />
            </div>
            <div className={styles.item_title}>{subString(data.title, 24)}</div>
            <div className={styles.item_subtitle}>{subString(data.subtitle, 80)}</div>
        </Link>
    );
}

export function PortfolioFeatured(props) {
    const { data } = props;

    return (
        <Link
            to={`/portfolio/${data.category}/${data.slug}`}
            className={`${styles.item_box} ${styles.featured}`}
            state={{ id: data._id }}
        >
            <div className={styles.item_img_box}>
                <img className={styles.item_img} src={data.images[0]} alt="portfolio" />
            </div>
            <div className={styles.item_title}>{subString(data.title, 24)}</div>
            <div className={styles.item_subtitle}>{subString(data.subtitle, 80)}</div>
        </Link>
    );
}
