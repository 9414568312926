import React, { useEffect, useState, useRef } from 'react';
import styles from './BackToTop.module.css';
import { ExpandLess } from '@styled-icons/material-rounded/ExpandLess';

export function BackToTop() {
    const prevScrollY = useRef(0);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll = () => {
        const currentScrollY = window.scrollY;
        if (prevScrollY.current < currentScrollY && currentScrollY > window.innerHeight) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
        prevScrollY.current = currentScrollY;
    };

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    return (
        <div>
            {isVisible && (
                <div className={styles.btn} onClick={scrollToTop}>
                    <ExpandLess />
                </div>
            )}
        </div>
    );
}
