import React, { useEffect, useState, useRef } from 'react';
import styles from './Header.module.css';
import { Link } from 'react-router-dom';
import { Menu } from '@styled-icons/material-rounded/Menu';
import { Close } from '@styled-icons/material-rounded/Close';

export function Header() {
    const prevScrollY = useRef(0);
    const [isScrollDown, setIsScrollDown] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll = () => {
        const currentScrollY = window.scrollY;
        if (prevScrollY.current < currentScrollY) {
            setIsScrollDown(true);
        } else {
            setIsScrollDown(false);
        }
        prevScrollY.current = currentScrollY;
    };

    return (
        <div className={isScrollDown ? `${styles.header_wrapper} ${styles.hidden}` : `${styles.header_wrapper}`}>
            <div className={styles.header_logo}>
                <Link to={`/`}>
                    <img className={styles.logo} src="/img/logo_adodaily.png" alt="logo" />
                </Link>
            </div>
            <div className={isMenuOpen ? `${styles.header_menu} ${styles.active}` : `${styles.header_menu}`}>
                <Link to={`/portfolio`} className={styles.menu_item} onClick={() => setIsMenuOpen(!isMenuOpen)}>
                    <div>Portfolio</div>
                </Link>
                <Link to={`/about`} className={styles.menu_item} onClick={() => setIsMenuOpen(!isMenuOpen)}>
                    <div>About</div>
                </Link>
            </div>
            {isMenuOpen ? (
                <Close className={styles.btn_menu} onClick={() => setIsMenuOpen(!isMenuOpen)} />
            ) : (
                <Menu className={styles.btn_menu} onClick={() => setIsMenuOpen(!isMenuOpen)} />
            )}
        </div>
    );
}
