export const sections = [
    {
        id: 'intro',
        name: 'Home',
    },
    {
        id: 'web',
        name: 'Web',
    },
    {
        id: 'app',
        name: 'App',
    },
    {
        id: 'design',
        name: 'Design',
    },
    // {
    //     id: 'others',
    //     name: 'Others',
    // },
    {
        id: 'conclusion',
        name: 'Conclusion',
    },
];

export const webSkills = [
    'HTML5',
    'CSS3',
    'JavaScript',
    'jQuery',
    'React',
    'Create React App',
    'Redux',
    'React Router',
    'MongoDB',
    'Node.js',
    'Express',
    'Firebase',
    'Bootstrap',
    'Tailwind CSS',
    'Postman',
    'NPM',
    'YARN',
    'Git',
];

export const appSkills = [
    'HTML5',
    'CSS3',
    'JavaScript',
    'React',
    'Redux',
    'React Router',
    'EXPO',
    'MongoDB',
    'Node.js',
    'Express',
    'Firebase',
    'Postman',
    'NPM',
    'YARN',
    'Git',
];

export const designSkills = [
    'Figma',
    'Adobe Illustrator',
    'Adobe PhotoShop',
    'Adobe After Effects',
    'Adobe Premiere Pro',
];

export const otherSkills = ['Blender', 'Unity'];

export const designUnits = ['px', 'em', 'rem', 'vw', 'vh', '%', 'calc()'];
