import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { login } from '../api';

// Define async functions
const loginAsync = createAsyncThunk('account/login', async ({ email, password }) => {
    try {
        const { data } = await login({ email, password });
        return data;
    } catch (err) {
        console.log(err);
    }
});

// Part1: Define Slice (including reducers and actions)
const initialState = {
    user: {},
    login: {
        hasLogin: false,
    },
    status: 'idle',
};

export const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        setUserInfo: (state, action) => {
            state.user = action.payload;
        },
        signOut: (state) => {
            state.login.hasLogin = false;
            Cookies.remove('jwt');
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(loginAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.login.hasLogin = true;
                Cookies.set('jwt', action.payload.token);
                state.user = { ...action.payload.data.user };
            })
            .addCase(loginAsync.rejected, (state, action) => {
                state.status = 'error';
                state.login.hasLogin = false;
            });
    },
});

// export state to global
export const selectUser = (state) => state.account.user;
export const selectLogin = (state) => state.account.login;
export const selectStatus = (state) => state.account.status;

// export actions to global
export const { setUserInfo, signOut } = accountSlice.actions;

// export async function to global
export { loginAsync };

// export reducer to global
export default accountSlice.reducer;
